<template>
    <div class="wrapper">
        <h1>Please confirm your vehicle:</h1>
        <div class="confirmation-wrapper" >
            <div class="cartiles" v-for="car in cars" :key="car.ID">
                <img :src="car.FirstMediaCompleteURLMedium">
                <ul class="vehicle-desc">
                    <li><strong>{{ car.BrandName }}</strong></li>
                    <li>{{ car.ModelName }}</li>
                    <li>{{ car.VersionName }}</li>
                    <li>{{ car.Year }}</li>
                    <li>{{ car.VehicleSeriesName }}</li>
                    <li><button class="btn" @click="confirmed(car, numPlates, state)">Confirm</button></li>
                </ul>
            </div>
        </div>
        <router-link to="/fullsearch" class="btn">No cars showing? Click Here</router-link>
    </div>
</template>

<script>
export default {
    name: 'confirmation',
    metaInfo: {
        title: 'Confirm Your Vehicle',
        titleTemplate: '%s | YourCarSold.com.au'
    },
    props: ['cars', 'numPlates', 'state'],  
    methods: {
        confirmed(carData, plates, state){
            this.$router.push({name: 'condition', params:{car: carData, numPlates: plates, state: state}})
        }
    }
}
</script>

<style scoped>
    .wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 20px 20px 80px 20px;
    }

    .confirmation-wrapper {
        background-color: rgb(0, 0, 0, 0.8);
        max-width: 960px;
        height: auto;
        width: 100%;
        margin: 0 auto;
        border: 1px solid #00B5E2;
        border-radius: 4px;
        margin-bottom: 10px;
        padding: 20px 20px 20px 20px;
        display: flex;
        flex-direction: column;
    }

    .cartiles {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 10px;
        color: #363636;
        font-size: larger;
        border-radius: 4px;
        background-color: silver;
        padding: 20px 10px;
        border-radius: 4px;
        width: 100%;
        max-width: 600px;
        margin: 0 auto;
        margin-bottom: 10px;
        border: 1px solid #00B5E2;
    }

    .cartiles img {
        width: 100%;
        max-width: 100%;
        border-radius: 4px;
        border: 1px solid #00B5E2;
    }

    .vehicle-desc {
        text-align: left;
        list-style-type: none;
        padding: 10px 10px;
        font-size: smaller;
    }

    .cartiles img {
        width: 100%;
    }

    .btn {
        margin: 10px 0px;;
        width: 100%;
    }
</style>