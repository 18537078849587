<template>
    <div class="wrapper">
        <BookingForm v-if="booking" />
        <section class="home-1">
            <div class="searchCenter">
                <Search heading="WE BUY ALL CARS" subTitle="Get Your Instant Kelley Blue Book™ Valuation - NOW!" />
                <button class="btn" @click="booking = !booking">Book Straight In Today >></button>
            </div>
            
            <div class="steps">
                <div class="cards">
                    <img src="../assets/money-tag2.webp" alt="Free Valuation" height="120px">
                    <h2>Free Valuation</h2>
                    <p>Just enter your Rego and State and confirm your details to get your <a href="https://www.kbb.com.au">Kelley Blue Book</a> valuation.</p>
                </div>
                <div class="cards">
                    <img src="../assets/car-card2.webp" alt="Bring Your Car Into Our Buying Centre For Inspection" height="120px">
                    <h2>Bring Your Car In</h2>
                    <p>Pay us a quick visit with your vehicle and after an inspection we will make you an offer.</p>
                </div>
                <div class="cards">
                    <img src="../assets/money-bag.webp" alt="Get Paid Instantly" height="120px">
                    <h2>Your Car Sold</h2>
                    <p> Once you’re happy with the deal the money can be in your account in under 60 seconds!</p>
                </div>
            </div>

            <Testimonials />

            <div class="new-locations">
                <div class="location-text">
                    <h2>Now with 3 Awesome Locations</h2>
                    <p>
                        We now have Buying Centres in 3 great locations; north, central and south of the river, making it even easier and more convenient to sell your car than ever before.
                    </p>
                    <br />
                    <p>    
                        We welcome walk-ins so don't hesitate to pop down to one of our centres and say hello, we will be happy to have a look at your car then and there, make some calls to our dealer network, and see what we can do to help you sell today.
                    </p>
                    <br />
                    <p>
                        You can find us any of the following locations:
                    </p>
                    <br />
                    <p>2/26 Lancaster Road<br>
                    <strong class="blueFont">Wangara</strong> WA 6065</p>
                    <br />
                    <p>37 Gt. Eastern Hwy<br>
                    <strong class="blueFont">Rivervale</strong> WA 6103</p>
                    <br />
                    <p>45B Dixon Road<br>
                    <strong class="blueFont">Rockingham</strong> WA 6168</p>
                    <br />
                    <p>
                        We look forward to seeing you!
                    </p>
                    <div class="logo-p">
                        <img src="../assets/logo.webp" alt="YourCarSold Logo" />
                    </div>
                </div>
                <div class="location-img">
                    <router-link to="where">
                        <img src="../assets/map-index.jpg" />
                    </router-link>
                </div>
            </div>

            <TheTeam :staff="this.$parent.staff" />

            <!-- <FAQ /> -->
                
            <LocationList :locations="this.$parent.locations" />
            
            <div class="bottom-nav">
                <router-link to="Privacy">Privacy</router-link>
                <router-link to="Disclaimer">Disclaimer</router-link>
                <router-link to="Terms">T's &amp; C's</router-link>
            </div>
        </section>
    </div>    
</template>

<script>
import Search from '../components/Search'
import BookingForm from './BookingForm.vue'
import Testimonials from './Testimonials.vue'
import LocationList from './LocationList.vue'
import TheTeam from './TheTeam.vue'
// import FAQ from './FAQ.vue'

export default {
    name: 'home',
    metaInfo: {
        title: 'Sell Your Car Today',
        titleTemplate: '%s | YourCarSold.com.au'
    },
    components: {
        Search,
        BookingForm,
        Testimonials,
        LocationList,
        TheTeam,
        // FAQ
    },
    data: () => {
        return {
            booking: false
        }
    }
}  
</script>

<style scoped>
    h2 {
        font-size: 2em;
        margin-bottom: 20px;
    }
    
    .wrapper {
        width: 100%;
        max-width: 1300px;
        padding: 20px;
        margin: 0 auto;
    }

    .home-1 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    p.steps {
        color: #fff;
        text-align: justify;
    }

    .steps {
        width: 100%;
        max-width: 1300px;
        margin: 0 auto;
        color: #fff;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .cards {
        text-align: center;
        padding: 20px 20px;
        background-color: rgb(21.2, 21.2, 21.2, 0.8);
        border: 1px solid #00B5E2;
        border-radius: 4px;
        box-shadow: 4px 4px 6px rgb(0, 0, 0, 0.8);
        font-size: smaller;
        margin-bottom: 10px;
    }

    .new-locations {
        width: 100%;
        max-width: 1300px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        padding: 20px 20px;
        background-color: rgb(21.2, 21.2, 21.2, 0.8);
        border: 1px solid #00B5E2;
        border-radius: 4px;
        margin-bottom: 20px;
        box-shadow: 4px 4px 6px rgb(0, 0, 0, 0.8);
    }

    .new-locations h2 {
        text-align: left;
    }

    .location-text {
        text-align: left;
        padding: 0px 10px;
        max-width: 800px;
    }

    .new-locations img {
        border-radius: 6px;
    }

    .whyMain {
        display: grid;
        grid-template-columns: 1fr;
        color:#fff; 
        padding: 20px 20px;
        width: 100%;
        max-width: 1300px;
        background-color: rgb(21.2, 21.2, 21.2, 0.8);
        border-radius: 4px;
        border: 1px solid #00B5E2;
        margin: 0px 0px 10px 0px;
        text-align: center;
        box-shadow: 4px 4px 6px rgb(0, 0, 0, 0.8);
    }

    .whyMain ul {
        font-size: larger;
        font-weight: bold;
        list-style-image: url('../assets/bullet.webp');
        padding-left: 20px;
    }

    .whyMain li {
        margin: 10px;
    }

    .points {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .whiteText {
        color: #fff;
    }

    .blueText {
        color: #00B5E2;
    }

    .bottom-nav {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 10px;
        margin: 0 auto;
        margin-bottom: 60px;
        background-color: rgb(21.2, 21.2, 21.2, 0.8);
        border-radius: 4px;
        border: 1px solid #00B5E2;
        padding: 10px 10px;
        font-size: smaller;
        width: 100%;
        max-width: 1300px;
        text-align: center;
        box-shadow: 4px 4px 6px rgb(0, 0, 0, 0.8);
    }

    .searchCenter {
        margin-bottom: 10px;
    }
    .logo-p {
        display: flex;
        margin: 30px 0px 30px 0px;
        
    }

    @media screen and (max-width: 1300px) {
        
        .logo-p {
            justify-content: center;
        }
        .new-locations {
            flex-direction: column;
        }

        .new-locations p {
            margin-bottom: 20px;
            text-align: center;
        }

        .new-locations h2 {
            text-align: center;
        }
    }

    @media screen and (min-width: 1300px) {
        .wrapper {
            padding-bottom: 80px;
        
        }

        .spiel h2 {
            text-align: left;
        }

        .steps {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            max-width: 1300px;
            gap: 10px;
        }
        
        .whyMain {
            grid-template-columns: 1fr 2fr;
            grid-template-areas: "spiel points points";
            text-align: justify;
        }

        .bottom-nav {
            margin-bottom: 0;
        }

        .cards {
            margin-bottom: 0px;
        }

    }

</style>