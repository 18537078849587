<template>
    <section class="why-wrapper">
        <div class="why-content">
            <h1>WHY CHOOSE US?</h1>
                <div class="conflex">
                    <div class="why-text">
                        <p>
                            <strong>At <a href="/">YourCarSold.com.au</a> we understand that selling a car can be a difficult, confusing and time-consuming process. Selling your car is something that you don't do every day, so it's difficult to know the best way to go about it.</strong>
                        </p>
                        <p>
                            Selling your car privately means you’ll be spending time and/or money with no guarantee of selling your car – not to mention fielding calls at all times of the day or night and having strangers at your home as well as the risk of test drive theft which unfortunately is becoming more & more common.
                        </p>
                        <p>
                            Trading or selling to a dealer often means running all over town trying to find both the car you want and a dealer who wants to trade/buy your car. Then you may be buying from a Mazda dealership who has no interest in your Hyundai or the dealership doesn’t deal with cars more than a few years old also making your car unattractive to trade.
                        </p>
                        <p>
                            At YourCarSold.com.au we do all the legwork for you and use our extensive dealer network across Australia to find the best buyer for your car. Our team has years of experience and know which buyer will want your car the most. After using our free online valuation service from Kelley Blue Book we work to get you the best price for your car and can pay you instant cash via OSKO or via normal bank transfer. It’s a simple 3 step process that means your car can be sold today!
                        </p>
                        <p>
                            More importantly as we do not retail cars ourselves we are fair, safe and most importantly honest in our assessment of your vehicle and we know how to get you the very best price for your car TODAY!
                        </p>
                        <p>
                            Our no obligation service is completely FREE – Why not give us a try now?
                        </p>
                    </div>
                    <div class="search-box">
                        <div class="stamp">
                            <span class="stamp-text">100% Free<br/>VALUATION</span>
                        </div>
                        <Search heading="TRY IT NOW" subTitle="COMPLETELY FREE" />
                    </div>
                </div>     
        </div>
    </section>
</template>

<script>
import Search from '../components/Search'
export default {
    name: 'why',
    metaInfo: {
        title: 'Why Choose Us?',
        titleTemplate: '%s | YourCarSold.com.au'
    },
    components: {
        Search
    }
}
</script>

<style scoped>
h1 {
    text-align: center;
    margin-bottom: 10px;
}

.why-wrapper {
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.why-text {
    text-align: left;
}

.why-content {
    background-color: rgb(0, 0, 0, 0.9);
    border-radius: 4px;
    border: 1px solid #00B5E2;
    width: 100%;
    max-width: 1300px;
    box-shadow: 4px 4px 6px rgb(0, 0, 0, 0.8);
    padding: 20px;
}

p {
    padding: 10px 0px;
}

.search-box {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
}

.conflex {
    display: flex;
    gap: 20px;
}

.stamp {
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(to bottom right, rgb(0, 181, 226, 0.8), rgb(205, 245, 255, 0.8), rgb(0, 181, 226, 0.8));
    border-radius: 16px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.8);
    height: 150px;
    padding: 10px;
    border: 3px solid #fff;
    margin-bottom: 20px;
}

.stamp-text {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3em;
    font-weight: 900;
    text-transform: uppercase;
    color: #000;
    border: 2px dashed #fff;
    border-radius: 10px;
    width: 100%;
    height: 100%;
    padding: 10px;
}

@media screen and (max-width: 1000px) {
    .why-wrapper {
        padding: 20px 20px 80px 20px;
    }

    .conflex {
        flex-direction: column;
    }
}

</style>