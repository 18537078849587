<template>
<div class="wrapper">
    <div class="staff-card">
        <h2>
            {{ member.fields.name }}
        </h2>
        <h3>
            {{ member.fields.position }} | {{ member.fields.branch }}
        </h3>

        <router-link :to="{name: 'staff-profile', params: {member: member, memberName: member.fields.name, memberBranch: member.fields.branch}}">
            <img :src="require('../assets/' + member.fields.img)" height="200px" width="180px" />
        </router-link>
        
        <a :href="`tel:+${member.fields.no}`">
            <button class="btn" type="button" :value="'Call' + member.fields.name.split(' ', 1)[0] + 'Now!'">
                Call {{ member.fields.name.split(' ', 1)[0] }} Now!
            </button>
        </a>
    </div>
</div>
</template>

<script>
export default {
    name: 'staff-card',
    props: ['member']
}
</script>

<style scoped>
.wrapper {
    display: flex;
    flex-direction: column;
    background-color: rgba(0,0,0,0.8);
    border: 1px solid #00b5e2;
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 10px;
    width: 100%;
    max-width: 240px;
    box-shadow: 4px 4px 6px rgb(0, 0, 0, 0.8);
}

.wrapper:hover {
    background-color: rgba(255,255,255,0.1);
}

.staff-card {
    display: flex;
    flex-direction: column;
}

h2, h3 {
    margin: 0;
    text-align: left;
    margin-bottom: 6px;
}

h2 {
    color: #fff;
}

p {
    text-align: left;
}

.btn {
    margin-top: 20px;
}

img {
    margin-top: 10px;
    border-radius: 10px;
}
</style>