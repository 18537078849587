<template>
<div class="header-wrap">
    <div class="addy-banner">
        <ul id="addy-list">
            <li class="addys"><i class="fab fa fa-map-pin white"></i><a href="https://www.google.com/maps/place/Your+Car+Sold/@-32.0359893,115.5056402,10z/data=!4m9!1m2!2m1!1syourcarsold!3m5!1s0x2a32ad87bf572c63:0xc8585e2967588880!8m2!3d-31.7964193!4d115.8195957!15sCgt5b3VyY2Fyc29sZFoNIgt5b3VyY2Fyc29sZJIBD3VzZWRfY2FyX2RlYWxlcg" target="_blank"><span class="add-show"> 2/26 Lancaster Rd</span> <strong>Wangara</strong><span class="add-show"> 6065</span></a></li>
            <li class="addys"><i class="fab fa fa-map-pin white"></i><a href="https://www.google.com/maps/place/Your+Car+Sold/@-32.0359893,115.5056402,10z/data=!4m9!1m2!2m1!1syourcarsold!3m5!1s0x2a32bb9d3ce0f4d1:0x9ae68a5e2a7dbcc5!8m2!3d-31.9565283!4d115.9065612!15sCgt5b3VyY2Fyc29sZJIBFG1vdG9yX3ZlaGljbGVfZGVhbGVy" target="_blank"><span class="add-show"> 37 Gt. Eastern Hwy</span> <strong>Rivervale</strong><span class="add-show"> 6103</span></a></li>
            <li class="addys"><i class="fab fa fa-map-pin white"></i><a href="https://www.google.com/maps/place/Your+Car+Sold/@-32.0359893,115.5056402,10z/data=!4m9!1m2!2m1!1syourcarsold!3m5!1s0x2a3283a0d55ec40b:0xd7ab4680b3116912!8m2!3d-32.2761785!4d115.7534285!15sCgt5b3VyY2Fyc29sZJIBCmNhcl9kZWFsZXI" target="_blank"><span class="add-show"> 45B Dixon Road</span> <strong>Rockingham</strong><span class="add-show"> 6168</span></a></li>
        </ul>
    </div>
    <header class="header-grid">
        <div class="logo">
            <router-link to="/">
                <img src="../assets/logo.webp" alt="YourCarSold Logo" width="180px">
            </router-link>
        </div>
        <nav class="top-nav">
            <router-link to="/how" class="hover">HOW</router-link> //
            <router-link to="/why" class="hover">WHY</router-link> //
            <router-link to="/where" class="hover">WHERE</router-link> //
            <router-link to="/about" class="hover">ABOUT</router-link> //  
            <router-link to="/blog" class="hover">BLOG</router-link>
        </nav>
        <div class="phone">
            Tel: <a href="tel:+61861866777">(08) 618 66 777</a>
        </div>
    </header>
</div>
</template>

<script>

export default {
    name: 'site-header',
    data() {
        return {
            
        }
    }
}
</script>

<style scoped>

    .addy-banner {
        height: 30px;
        width: 100%;
        color: #fff;
        background-color: #00B5E2;
        position: fixed;
        top: 0;
        left: 0;
        padding: 4px 20px;
        z-index: 100;
    }

    #addy-list {
        width: 100%;
        max-width: 1300px;
        text-decoration: none;
        list-style-type: none;
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        margin: 0 auto;
    }

    #addy-list li a {
        color: #fff;
    }

    #addy-list li a:hover {
        color: #363636;
    }

    .header-wrap {
        display: flex;
        width: 100%;
        border-bottom: 1px solid #00b5e2;
        min-height: 130px;
        background-color: rgba(0, 0, 0, 0.9);
        padding: 50px 0px 20px 0px;
        z-index: 99;
    }

    .header-grid {
        display: flex;
        align-items: center;
        flex-direction: column; 
        width: 100%;
        
    }

    .top-nav {
        color: #fff;
        margin-top: .5rem;
        margin-bottom: .5rem;
        font-size: small;
        letter-spacing: 2px;
    }

    .top-nav a {
        color: #fff;
        text-decoration: none;
        padding: 4px 10px;
    }

    .top-nav a:hover {
        color: #00b5e2;
        transition: 0.8s;
    }

    .hover {
        display: inline-block;
        position: relative;
    }

    .hover::before {
        content: '';
        position: absolute;
        width: 80%;
        transform: scaleX(0);
        height: 1px;
        bottom: 0;
        left: 8%;
        background-color: #00B5E2;
        transform-origin: bottom right;
        transition: transform 0.25s ease-out;
    }

    .hover:hover::before {
        transform: scaleX(1);
        transform-origin: bottom left;
    }

    .phone a {
        text-decoration: none;
        color: #00b5e2;
    }

    .phone a:hover {
        color: #fff;
        transition: 0.8s;
    }

    .white {
        color: #ffffff;
    }

    @media screen and (max-width: 1300px) {
        .add-show {
            display: none;
        }
    }

    @media screen and (min-width: 1300px) {
        
        
        .header-grid {
            flex-direction: row;
            justify-content: space-between;
            max-width: 1300px;
            margin: 0 auto;
        }

        

        nav {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .logo {
            text-align: left;
        }

        .phone {
            text-align: right;
        }

    }
</style>