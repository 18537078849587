<template>
  <div class="about-wrapper">
    <div class="about-content">
      <h1>ABOUT US</h1>
      <div class="about">
        <div class="about-l">
          <p>
            <strong>
              <a href="#">YourCarSold.com.au</a> was launched when it’s founders
              saw a need for a better way for the average consumer to sell their
              car in a fair, safe &amp; honest environment.</strong>
          </p>
          <p>
            Our simple three step process provides you with a quick, safe and
            convenient way of selling your car. Free from the hassles of private
            sale or the inevitable negotiation with a dealership.
          </p>
          <p>
            Our team has years of vehicle valuation experience and a huge network
            of buyers Australia wide. Be it a $1000 cheapie or $100,000 luxury car
            we have the expertise to know who will pay the most for any particular
            vehicle – and payment is available in under 60 seconds!
          </p>
          <p>
            We pride ourselves on our transparent process and integrity in our
            dealings and will be upfront and honest in our assessment of your car.
            We will give you the facts whether you choose to sell to us or try
            private sale – we are here to help.
          </p>
          <p>
            Get your <strong>FREE</strong> valuation now to start the process of
            getting <router-link to="/">Your Car SOLD</router-link>.
          </p>
        </div>
        <div class="about-r">
          <img src="..\assets\Women-Signing-Deal.png" />
          <router-link to="/">
            <button class="btn">Get Your Valuation Now >></button>
          </router-link>
        </div>
      </div>

      <TheTeam :staff="this.$parent.staff" />
      <Testimonials />

      <div class="search-module">
        <Search heading="DON'T HESITATE" subTitle="GET YOUR FREE VALUATION TODAY!" />
      </div>

    </div>
  </div>
</template>

<script>
import Search from "../components/Search";
import Testimonials from "./Testimonials.vue";
import TheTeam from "./TheTeam.vue"

export default {
  name: 'about',
  props: ['staff'],
  metaInfo: {
    title: "About Us",
    titleTemplate: "%s | YourCarSold.com.au",
  },

  components: {
    Search,
    Testimonials,
    TheTeam,
  },
};
</script>

<style scoped>
h1,
h2 {
  text-align: center;
}

h1 {
  margin: 20px 0px;
}

hr {
  width: 90%;
}

.btn {
  margin-bottom: 10px;
}

.about-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 20px 80px 20px;
}

.about-content {
  background-color: rgb(0, 0, 0, 0.9);
  border-radius: 4px;
  border: 1px solid #00b5e2;
  box-shadow: 4px 4px 6px rgb(0, 0, 0, 0.8);
  width: 100%;
  max-width: 1300px;
  padding: 0px 20px 20px 20px;
}

.about {
  display: flex;
  gap: 20px;
  padding-bottom: 10px;
}

.about-l {
  text-align: left;
}

.about-r {
  max-width: 100%;
}

.about-r img {
  border-radius: 6px;
  margin-top: 10px;
  width: 400px;
}

.about-contact-links {
  color: #fff;
}

.about-contact-links:hover {
  color: #00b5e2;
}

p {
  font-size: small;
  padding: 10px 0px;
}

.dateStyle {
  font-size: x-small;
}

.search-module {
  border-top: 1px solid #00b5e2;
}



@media screen and (max-width: 780px) {

  .about {
    flex-direction: column;
  }

  .about-wrapper {
    padding-bottom: 80px;
  }

  .about-r img {
    width: 100%;
  }

}
</style>