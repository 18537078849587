<template>
    <section class="blog-container">
        <section class="blog-item">
            <img class="hero-image" :src="blog.fields.heroImage.fields.file.url" />
            <h1>{{ blog.fields.title }}</h1>
            <p class="date">{{ blog.sys.createdAt.toString().replace("T", " @ ").slice(0, -8) }}</p>
            <RichTextRenderer v-if="blog.fields" :document="blog.fields.body" :nodeRenderers="renderNodes()"/>        
        </section>
        <section class="search-box">    
                <Search heading="GET YOUR VALUATION" subTitle="JUST ENTER YOUR REGO" />
        </section>
    </section>
</template>

<script>
import RichTextRenderer from 'contentful-rich-text-vue-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import Search from '@/components/Search.vue';

const contentful = require('contentful')

const client = contentful.createClient({
  space: '56r2v9ult7u4',
  environment: 'master', 
  accessToken: process.env.VUE_APP_CONTENTFUL_ACCESS_TOKEN,
  
})


export default {
    name: 'blog',
    components: {
        RichTextRenderer,
        Search
    },
    data(){
        return {
            blog: [],
        }
    },
    metaInfo: {
        title: 'Your Car Sold - Blog',
        titleTemplate: '%s | YourCarSold.com.au'
    },
    methods: {
        renderNodes() {
            return {
                [BLOCKS.EMBEDDED_ASSET]: node => {
                    return <img src={node.data.target.fields.file.url} class="contentful-image"/>
                },
            }
        }
    },
    created() {
        client.getEntry(this.$route.params.id)
        .then((entry) => {
            this.blog = entry
        })
        .catch(console.error)
    }
}
</script>

<style scoped>
    .blog-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        padding: 20px 20px 100px 20px;
    }

    .date {
        color: #00B5E2;
        margin: 20px 0px 0px 0px;
    }

    #search-heading {
        letter-spacing: 4px;
        margin: 10px 0px;
        text-align: center;
    }

    h1 {
        text-transform: uppercase;
        margin: 20px 0px 10px 0px;
        text-align: left;
    }
    
    h2 {
        margin: 10px 0px;
        text-align: left;
        font-size: 1.5em;
    }

    .blog-item {
        display: flex;
        flex-direction: column;
        color:#fff;
        width: 100%;
        max-width: 1300px;
        background-color: rgb(21.2, 21.2, 21.2, 0.8);
        border-radius: 4px;
        border: 2px solid #00B5E2;
        text-align: left;
        padding: 20px;
        box-shadow: 4px 4px 6px rgb(0, 0, 0, 0.8);
    }

    p {
        text-align: left;
        margin: 20px 0px;
        line-height: 24px;
    }

    p:last-of-type {
        border-bottom: 1px solid #00B5E2;
        padding-bottom: 20px;
    }

    .contentful-image {
        width: 100%;
        max-width: 960px;
        border-radius: 6px;
        box-shadow: 4px 4px 6px rgb(0, 0, 0, 0.8);
    }

    .hero-image {
        width: 100%;
        border-radius: 4px;
    }

    @media screen and (max-width: 960px) {

    }
</style>