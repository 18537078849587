<template>
    <div class="wrapper">
        <div class="form-wrapper">
            <h1>Please Enter Your Details</h1>
            <label for="name">First Name:</label>
            <input type="text" v-model="firstname" class="input-text" placeholder="First Name" required>
            <label for="name">Last Name:</label>
            <input type="text" v-model="lastname" class="input-text" placeholder="Last Name" required>
            <label for="name">Email:</label>
            <input type="email" v-model="email" class="input-text" placeholder="Email" required>
            <label for="name">Phone:</label>
            <input type="number" @keyup.enter="finalSubmit" v-model="phone" class="input-text" placeholder="Phone" required>
            <button class="btn" @click="finalSubmit">Get Valuation >></button>
        </div>
        
        <div class="spin">
            <Spinner />
        </div>
    </div>
</template>

<script>
import Spinner from 'vue-simple-spinner';
import VCard from 'vcard-creator';

const client = require('@mailchimp/mailchimp_marketing');

client.setConfig({
  apiKey: process.env.VUE_APP_MCKEY,
  server: "us7",
});

export default {
    name: 'final-form',
    metaInfo: {
        title: 'Your Details',
        titleTemplate: '%s | YourCarSold.com.au'
    },
    props: ['output', 'carData'],
    components: {
        Spinner,
    },
    data: () => {
        return {
            firstname: '',
            lastname: '',
            email: '',
            phone: '',
            location: '',
            blockList: [
                '88kevinmuchira@gmail.com',
                'mohamed.fayaz@gmail.com'
            ]
        }
    },
    methods: {
        finalSubmit() {
            if( this.$data.firstname == '' ||
                this.$data.lastname == '' ||
                this.$data.email == '' ||
                this.$data.phone == '' ||
                this.$data.blockList.includes(this.$data.email) ||
                this.$data.firstname.includes('nope') ||
                this.$data.firstname === 'x' ||
                this.$data.lastname.includes('nope') ||
                this.$data.lastname === 'x' ||
                this.$data.email.includes('nope') ||
                this.$data.email.includes('@nope.com') ) {
                    this.$alert('Please enter required fields', 'Error','error')
                    return
            }
            document.querySelector('.spin').style.display="flex";

            const myVCard = new VCard()
            const firstName = this.firstname
            const lastName = this.lastname
            const custNumber = this.phone
            const custEmail = this.email

            myVCard.addName(lastName, firstName).addEmail(custEmail).addPhoneNumber(custNumber, 'WORK')

            const findValuation = this.$props.output.indexOf('$')
            const myValuation = this.$props.output.substr(findValuation, 8)

            fetch('https://ycs-ms.onrender.com' , {
                method: 'post',
                body: JSON.stringify({
                    formData: {name: this.$data.firstname, email: this.$data.email, phone: this.$data.phone},
                    carData: this.$props.carData,
                    valuation: myValuation.replace(/<\/?("[^"]*"|'[^']*'|[^>])*(>|$)/g, ""),
                    card: myVCard.toString()
                }),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
            .then((response) => {
                return response.json()
            })
            .then((jsonData) => {
                this.cars = jsonData.Response

                this.$gtag.event('generate_lead', {
                    'event_category': 'Lead',
                    'event_label': 'Valuation End Page',
                    'value': 1000,
                    'send_to': 'AW-476063102/ODYcCKqboPYBEP7KgOMB',
                    'em': this.$data.email
                })

                // Add email to mail chimp
                this.addToMailchimp()
                this.$router.push({name: 'valuation', params: {output: this.$props.output, state: this.$props.carData.state, carData: this.$props.carData, enhanced_conversion_data: this.$data.email}})
            })

            

            
        },
        async addToMailchimp(){
           let info = {
                email_address: this.$data.email,
                status: "pending",
                merge_fields: {"FNAME": this.$data.firstname, "LNAME": this.$data.lastname, "PHONE": this.$data.phone}
            }

            fetch('https://ycs-server.herokuapp.com/mailchimp', {
                method: 'post',
                body: JSON.stringify({
                    data: info
                }),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
            .then((response) => {
                return response.json()
            })
            .then((jsonresponse)=>{
                console.log(jsonresponse)
            })
            .catch(e => {
                console.log(e)
            })

        }

    }
}
</script>

<style scoped>
    .wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px;
    }

    .form-wrapper {
        background-color: rgb(0, 0, 0, 0.8);
        max-width: 960px;
        height: auto;
        width: 100%;
        margin: 0 auto;
        border: 1px solid #00B5E2;
        border-radius: 4px;
        padding: 20px 20px 40px 20px;
        text-align: left;
    }

    label {
        display: none;
    }

    .input-text {
        margin: 0 auto;
        width: 100%;
        max-width: 500px;
        padding: 10px 6px;
        border: 1px solid #00B5E2;
        border-radius: 4px;
        display: block;
        margin-bottom: 10px;
        background-image: none;
    }

    .spin {
        position: fixed;
        height: 100vh;
        width: 100vw;
        background-color: rgb(0, 0, 0, 0.6);
        z-index: 1;
        top: 0;
        left: 0;
        display: none;
        justify-content: center;
        align-items: center;
    }
</style>