<template>
  <div id="app" @mouseleave="modalActive = true">
    <!-- <ExitModal @close-modal="modalActive = !modalActive" v-show="modalActive" /> -->
    <SiteHeader />
    <vue-particles
      color="#00b5e2"
      class="behind"
      :lineOpacity="0.1"
      :particleOpacity="0.3"
    ></vue-particles>
    <transition
      mode="out-in"
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="animate__animated animate__fadeOut"
    >
      <router-view></router-view>
    </transition>
    <SiteFooter />
  </div>
</template>

<script>
import SiteHeader from './components/SiteHeader';
import SiteFooter from './components/SiteFooter';
// import ExitModal from './components/ExitModal.vue'

const contentful = require('contentful');

const client = contentful.createClient({
  space: process.env.VUE_APP_CONTENTFUL_SPACE_ID,
  environment: 'master',
  accessToken: process.env.VUE_APP_CONTENTFUL_ACCESS_TOKEN,
});

export default {
  name: 'App',
  components: {
    SiteHeader,
    SiteFooter,
    //  ExitModal,
  },
  data: () => {
    return {
      // modalActive: false,
      locations: [],
      staff: [],
    };
  },
  created() {
    client
      .getEntries({ content_type: 'staffMember' })
      .then((entries) => {
        this.staff = entries.items;
      })
      .catch(console.error);

    client.getEntries({ content_type: "location" })
    .then((locEntries) => {
      this.locations = locEntries.items[0].fields.locations;
    })
    .catch(console.error);
  },
};
</script>

<style>
@font-face {
  font-family: 'Gotham', Helvetica, Arial, sans-serif;
  src: url('./assets/fonts/GothamPro-Light.woff2') format('woff2'),
    url('./assets/fonts/GothamPro-Light.woff') format('woff');
  font-display: swap;
}

::-webkit-scrollbar {
  display: none;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  padding: 0;
  background-color: #363636;
  background-image: linear-gradient(to bottom right, #363636, #000000, #363636);
  background-size: cover;
  background-attachment: fixed;
}

#app {
  font-family: 'Gotham', Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #fff;
  height: 100vh;
  width: 100%;
}

.behind {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: -1;
}

a {
  color: #00b5e2;
  text-decoration: none;
}

a:hover {
  color: #fff;
  transition: 0.6s;
}

h1 {
  font-weight: 900;
  font-size: 2.4em;
  margin: 4px 0px;
}

h2 {
  color: #00b5e2;
  margin: 4px 0px;
  font-size: 1rem;
}

h3 {
  color: #00b5e2;
  font-size: 0.8rem;
}

.blueFont {
  color: #00b5e2;
}

.btn {
  display: block;
  padding: 12px 10px;
  background-color: rgb(21.2, 21.2, 21.2, 0.8);
  color: #00b5e2;
  border: 1px solid #00b5e2;
  border-radius: 4px;
  margin: 0 auto;
  margin-top: 10px;
  cursor: pointer;
  width: 100%;
  max-width: 500px;
  box-shadow: 4px 4px 6px rgb(0, 0, 0, 0.8);
}

.btn:active {
  transform: translate(2px, 2px);
}

.btn:hover {
  color: #fff;
  background-color: #00b5e2;
  transition: 0.3s;
  transform: translate(2px, 2px);
}

.input-text {
  padding: 14px 50px;
  border: 1px solid #00b5e2;
  border-radius: 4px;
  background-image: url('./assets/wa2.webp');
  background-position: left;
  background-repeat: no-repeat;
  background-size: contain;
  font-size: large;
  width: 100%;
  box-shadow: 2px 2px 2px rgb(21.2, 21.2, 21.2, 0.8);
  height: 50px;
}

.state-input {
  padding: 13px 2px;
  border: 1px solid #00b5e2;
  border-radius: 4px;
  margin-top: 6px;
  font-size: large;
  width: 100%;
  box-shadow: 2px 2px 2px rgb(21.2, 21.2, 21.2, 0.8);
}

@media screen and (max-width: 480px) {
  .input-text {
    width: 100%;
  }

  .state-input {
    margin-left: 0px;
    width: 100%;
  }
}
</style>
