<template>
    <div class="not-found-wrapper">
        <div class="not-found-center">
            <h1>404 Error - Page Not Found</h1>
            <h2>Sorry This Page Doesn't Exist</h2>
            <p>Sorry! You have reached a page that doesn't exist, please <router-link to="/">click here</router-link> to return to our Search page or follow the menu above to learn more.</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'not-found',
    data() {
        return {

        }
    },
    metaInfo: {
        title: '404 Not Found',
        titleTemplate: '%s | YourCarSold.com.au'
    },
}
</script>

<style>
    .not-found-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
        width: 100%;
        padding: 20px;
    }
</style>