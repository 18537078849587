<template>
  <div class="wrapper">
    <div class="main">
      <h1>Sell Your Car - {{ $route.params.suburb }} | WA</h1>
      <h2>{{ $route.params.suburb }} | {{ $route.params.region }}</h2>
      <p>
            Are you in <strong>{{ $route.params.suburb }} WA</strong> and looking to
            sell your used car the quick, easy and painless way? At
            <strong>YourCarSold.com.au</strong> our sole directive is to help you
            get the best price possible for your used vehicle with the least amount
            of hard work for you the customer.
      </p>
      <p>
            We have an extensive dealer network
            that we have regular contact with and have no issues finding buyers for
            every type of car; no matter the age, make, model or condition. And with
            branches all along the coast we have no issues servicing you guys in the
            {{ $route.params.region }} Metro area.
      </p>
      <p class="address" v-show="$route.params.region == 'North'">
        Find our nearest branch at: <br>
        <a href="https://maps.google.com/maps?cid=14436392137456650368&authuser=1&_ga=2.37561764.584584341.1628818239-1151572983.1607501795">2/26 Lancaster Rd, <strong>Wangara</strong> 6065</a>
      </p>
      <p class="address" v-show="$route.params.region == 'Central'">
        Find our nearest branch at: <br>
        <a href="https://www.google.com/maps/place/Your+Car+Sold/@-32.0359893,115.5056402,10z/data=!4m9!1m2!2m1!1syourcarsold!3m5!1s0x2a32bb9d3ce0f4d1:0x9ae68a5e2a7dbcc5!8m2!3d-31.9565283!4d115.9065612!15sCgt5b3VyY2Fyc29sZJIBFG1vdG9yX3ZlaGljbGVfZGVhbGVy">37 Gt. Eastern Hwy. <strong>Rivervale</strong> 6103</a>
      </p>
      <p class="address" v-show="$route.params.region == 'South'">
        Find our nearest branch at: <br>
        <a href="https://www.google.com/maps/place/Your+Car+Sold/@-32.2794871,115.7444838,15.75z/data=!4m9!1m2!2m1!1syour+car+sold!3m5!1s0x2a3283a0d55ec40b:0xd7ab4680b3116912!8m2!3d-32.2761785!4d115.7534285!15sCg15b3VyIGNhciBzb2xkkgEKY2FyX2RlYWxlcg">45B Dixon Rd. <strong>Rockingham</strong> 6168</a>
      </p>

      <Search
        class="search-loc"
        heading="Search Your Rego Now"
        subTitle="Get Your Free Valuation Today"
      />
    </div>
  </div>
</template>

<script>
import Search from './Search.vue';
export default {
  name: 'location-lander',
  props: ['locations'],
  data: () => {
    return {};
  },
  components: {
    Search,
  },
};
</script>

<style scoped>
.wrapper {
  padding: 0px 10px 0px 10px;
  height: calc(100vh + 60px);
}
.main {
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 6px;
  border: 1px solid #00b5e2;
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
  margin-top: 20px;
  min-height: 600px;
  padding: 20px;
  line-height: 26px;
}

.search-loc {
  margin-top: 20px;
}

h1 {
    line-height: 1em;
}

.address {
    font-size: large;
}

p {
  max-width: 960px;
  margin: 0 auto;
  padding: 10px;
}
</style>
