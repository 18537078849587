import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App.vue'
import Spinner from 'vue-simple-spinner'
import VueMeta from 'vue-meta'
import VueGtag from 'vue-gtag'
import Tabs from 'vue-material-tabs'

import Privacy from './components/Privacy.vue'
import Disclaimer from './components/Disclaimer.vue'
import Terms from './components/Terms.vue'
import How from './components/How.vue'
import Why from './components/Why.vue'
import About from './components/About.vue'
import Where from './components/Where.vue'
import blog from './components/blog.vue'
import post from './components/post.vue'
import Home from './components/Home.vue'
import Confirmation from './components/Confirmation.vue'
import Condition from './components/Condition.vue'
import Form from './components/Form.vue'
import Valuation from './components/Valuation.vue'
import FullSearch from './components/FullSearch.vue'
import LocationLander from './components/LocationLander.vue'
import StaffProfile from './components/StaffProfile.vue'
import VueSimpleAlert from 'vue-simple-alert'
import NotFound from './components/NotFound.vue'
import 'animate.css'
import VueCarousel from 'vue-carousel';
import VueShave from 'vue-shave'
import VueParticles from 'vue-particles'



Vue.use(VueRouter);
Vue.use(VueMeta);
Vue.use(VueSimpleAlert);
Vue.use(Tabs);
Vue.use(VueCarousel);
Vue.use(VueShave);
Vue.use(VueParticles);

const routes = [
  { path: '/privacy', component: Privacy, name: 'privacy' },
  { path: '/disclaimer', component: Disclaimer, name: 'disclaimer' },
  { path: '/terms', component: Terms, name: 'terms' },
  { path: '/how', component: How, name: 'how' },
  { path: '/why', component: Why, name: 'why' },
  { path: '/about', component: About, name: 'about' },
  { path: '/where', component: Where, name: 'where' },
  { path: '/blog', component: blog, name: 'blog' },
  { path: '/location/:suburb', component: LocationLander, name: 'location-lander', props: true },
  { path: '/team/:memberBranch/:memberName', component: StaffProfile, name: 'staff-profile', props: true },
  { path: '/blog/:id', component: post, name: 'post' },
  { path: '/', component: Home, name: 'home' },
  { path: '/confirmation', component: Confirmation, name: 'confirmation', props: true},
  { path: '/condition', component: Condition, name: 'condition', props: true},
  { path: '/form', component: Form, name: 'final-form', props: true},
  { path: '/valuation', component: Valuation, name: 'valuation', props: true},
  { path: '/fullsearch', component: FullSearch, name: 'full-search', props: true},
  { path: '/*', component: NotFound, name: 'not-found' }
]


const router = new VueRouter({
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes,
  mode: 'history'
})

Vue.use(VueGtag, {
  config: {
    id: "UA-182988826-1",
    params: {
      allow_enhanced_conversions: true,
    }
  }
}, router);


Vue.config.productionTip = false


new Vue({
  render: h => h(App),
  router,
  Spinner,
}).$mount('#app')