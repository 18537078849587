<template>
    <section class="step-container">
        <h1>HOW IT WORKS</h1>
        <div class="steps">    
            <div class="cards">
                <img src="../assets/money-tag2.webp" alt="Free Online Valuation" height="120px">
                <h2>FREE Online Valuation</h2>
                <p>
                    To find out how much your car could be worth simply enter your rego and we will provide you with a free online valuation provided by Kelley Blue Book – a company with over 90 years vehicle valuation experience.
                </p>
            </div>
            <div class="cards">
                <img src="../assets/car-card2.webp" alt="Bring Your Car In" height="120px">
                <h2>Sell Your Car</h2>
                <p>
                    Bring your car, along with service history, spare keys, rego papers, ID &amp; finance payout letter (if app) into our buying centre and we will utilise our Australia-wide network to get the best price for your car on the spot – no waiting & no consignment.
                </p>
            </div>
            <div class="cards">
                <img src="../assets/money-bag.webp" alt="Get Paid Instantly" height="120px">
                <h2>Get Paid...FAST!</h2>
                <p>
                    Once you’re happy with the deal you sign off on the sale and the money can be in your account in under 60 seconds!
                <br><br>   
                    That’s it – Your Car IS Sold
                </p>
            </div>
        </div>
        <section class="whyMain">
            <div class="spiel">
                <h2>STILL HAVE QUESTIONS?</h2>
                <p>
                    If you still have any questions or want to know more then please feel free to call us directly on:
                    <button type="button" class="btn" @click="callUs" value="Tel: (08) 618 66 777"><strong>Tel: (08) 618 66 777</strong></button>
                </p>
            </div>
            <div class="points">
                <ul>
                    <li>We Buy Any Car</li>
                    <li>Instant Payment</li>
                    <li>Quick + Easy Process</li>
                    <li>Yes, we can pay out finance</li>
                </ul>
            </div>
        </section>
    </section>
</template>

<script>

export default {
    name: 'how',
    metaInfo: {
        title: 'How Does It Work?',
        titleTemplate: '%s | YourCarSold.com.au'
    },
    methods: {
        callUs() {
            window.open("tel:+61861866777")
        }
    }
}
</script>

<style scoped>
h2 {
    font-size: 1.6em;
}

.steps {
    width: 100%;
    max-width: 1300px;
    margin: 0 auto;
    padding-top: 10px;
    color: #fff;
    text-align: center;
}

.step-container {
    padding: 20px;
    margin: 0 auto;
}

.cards {
    text-align: center;
    padding: 20px 20px;
    background-color: rgb(21.2, 21.2, 21.2, 0.8);
    border: 2px solid #00B5E2;
    border-radius: 4px;
    margin-bottom: 10px;
    max-width: 1300px;
    box-shadow: 4px 4px 6px rgb(0, 0, 0, 0.8);
}

.whyMain {
    display: grid;
    grid-template-columns: 1fr;
    color:#fff;
    width: 100%;
    max-width: 1300px;
    margin: 0 auto;
    background-color: rgb(21.2, 21.2, 21.2, 0.8);
    border-radius: 4px;
    border: 2px solid #00B5E2;
    text-align: center;
    padding: 20px 20px;
    box-shadow: 4px 4px 6px rgb(0, 0, 0, 0.8);
    margin-bottom: 100px;
}

.whyMain ul {
    list-style-image: url('../assets/bullet.webp');
    font-size: larger;
    font-weight: bold;
    padding-left: 20px;
}

.whyMain li {
    margin: 10px;
}

.points {
    display: flex;
    justify-content: center;
    align-items: center;
}

.ph-no {
    font-size: x-large;
}


@media screen and (min-width: 1300px) {
    
    .whyMain {
        grid-template-columns: 1fr 2fr;
    }

    
    .spiel {
        padding-left: 60px;
        text-align: left;
    }

    .spiel h2 {
        text-align: left;
        margin-top: 0;
    }

    .steps {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 10px;
    }

    .points {
        font-size: x-large;
        text-align: left;
    }

    .points li {
        padding-left: 20px;    
    }
}

</style>