<template>
    <section class="full-search-wrapper">
        <h1>Whoops! Looks Like We Can't Find Your Rego</h1>
        <h2>Please Use This Form To Find Your Vehicle:</h2>
        <div class="form-wrapper">
            <label for="make">Make: </label>
            <select name="make" id="make" aria-label="Make" v-model="make" @change="getModels()">
                <option disabled selected :value="null">Select Make..</option>
                <option v-for="make in makes" :key="make.Name" :value="make.ID">{{ make.Name }}</option>
            </select>
            <label for="model">Model: </label>
            <select name="model" id="model"  aria-label="Model" v-model="model" @change="getYears()">
                <option disabled selected :value="null">Select Model..</option>
                <option v-for="model in models" :key="model.brandID" :value="model.ID">{{ model.Name }}</option>
            </select>
            <label for="year">Year: </label>
            <select name="year" id="year" aria-label="Year" v-model="year" @change="getVersions()">
                <option disabled selected :value="null">Select Year..</option>
                <option v-for="year in years" :key="year.Year">{{ year.Year }}</option>
            </select>
            <label for="version">Version: </label>
            <select name="version" id="version" aria-label="Version" v-model="version" @change="getVehicles()">
                <option disabled selected :value="null">Select Version..</option>
                <option v-for="version in versions" :key="version.KBBID" :value="version">{{ version.Name }}</option>
            </select>
            <label for="vehicle">Confirm Your Vehicle: </label>
            <select name="vehicle" id="vehicle" aria-label="Vehicle" v-model="vehicle">
                <option disabled selected :value="null">Select Vehicle..</option>
                <option v-for="vehicle in vehicles" :key="vehicle.ID" :value="vehicle">
                    {{ vehicle.BrandName + ' - ' + vehicle.ModelName + ' - ' + vehicle.VersionName + ' - ' + vehicle.CC + 'cc - ' + vehicle.VehicleSeriesName }}
                </option>
            </select>
            <button class="btn" @click="getVal(version)">
                Get My Valuation >>
            </button>
        </div>
    </section>
</template>

<script>
export default {
    name: 'full-search',
    props: ['state'],
    data: ()=>{
        return {
            makes: [],
            models: [],
            years: [],
            versions: [],
            vehicles: [],
            make: null,
            model: null,
            year: null,
            version: null,
            vehicle: null,
        }
    },
    mounted: function() {
            this.getMakes()
        },
    methods: {
        getMakes() {
            fetch('https://api.kbb.com.au/Gps/GetAllVehicleBrand?' + new URLSearchParams({
                securityToken: process.env.VUE_APP_KBB_KEY,
                language: 'en-AU'
                }) , {
                    method: 'get',
                })
                .then((response) => {
                    return response.json()
                })
                .then((jsonData) => {
                    this.makes = jsonData.Response
                })
        },
        getModels() {
            fetch('https://api.kbb.com.au/Gps/GetAllVehicleModel?' + new URLSearchParams({
                securityToken: process.env.VUE_APP_KBB_KEY,
                language: 'en-AU',
                BrandID: this.$data.make
                }) , {
                    method: 'get',
                })
                .then((response) => {
                    return response.json()
                })
                .then((jsonData) => {
                    this.models = jsonData.Response
                })
        },
        getYears() {
            fetch('https://api.kbb.com.au/Gps/GetAllYears?' + new URLSearchParams({
                securityToken: process.env.VUE_APP_KBB_KEY,
                }) , {
                    method: 'get',
                })
                .then((response) => {
                    return response.json()
                })
                .then((jsonData) => {
                    this.years = jsonData.Response
                })
        },
        getVersions() {
            fetch('https://api.kbb.com.au/Gps/GetAllVehicleVersion?' + new URLSearchParams({
                securityToken: process.env.VUE_APP_KBB_KEY,
                language: 'en-AU',
                modelID: this.$data.model,
                year: this.$data.year
                }) , {
                    method: 'get',
                })
                .then((response) => {
                    return response.json()
                })
                .then((jsonData) => {
                    this.versions = jsonData.Response
                })
        },
        getVehicles() {
            fetch('https://api.kbb.com.au/Gps/GetAllVehicle?' + new URLSearchParams({
                securityToken: process.env.VUE_APP_KBB_KEY,
                language: 'en-AU',
                vehicleID: this.$data.version.KBBID,
                year: this.$data.year
                }) , {
                    method: 'get',
                })
                .then((response) => {
                    return response.json()
                })
                .then((jsonData) => {
                    this.vehicles = jsonData.Response.VehicleList
                })
        },
        getVal() {
            if (this.$data.vehicle == null){
                this.$alert('Please enter required fields', 'Error','error')
                return
            }

            var manualCarData = this.$data.vehicle

            this.$router.push({name: 'condition', params: {car: manualCarData, state: this.$props.state}})
        }
    }
}
</script>

<style scoped>
    
    .full-search-wrapper {
        display: flex;
        flex-direction: column;
        padding: 20px;
    }

    .form-wrapper {
        margin: 0 auto;
        text-align: left;
        width: 100%;
        max-width: 500px;
        padding: 20px;
        background-color: rgba(0, 0, 0, 0.8);
        border: 1px solid #00B5E2;
        border-radius: 4px;
        box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.8);
        margin-bottom: 160px;
    }

    select {
        display: block;
        width: 100%;
        max-width: 500px;
        height: 40px;
        padding-left: 6px;
        border-radius: 4px;
        margin: 0 auto; 
        margin-bottom: 10px;
        font-size: 16px;
    }

    input:focus, input:focus-within, select:focus {
        outline: none;
    }

</style>