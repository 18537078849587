<template>
    <div class="wrapper">
        <h1>Enter the Kilometers and Condition</h1>
        <div class="condition-wrapper">
            <div class="cond-inner">
                <input type="number" class="input-text" v-model.number="kilometers" placeholder="Enter Kilometers...">
                <select name="condition" id="condition" v-model="condition" class="state-input">
                    <option disabled selected value="">Select Condition...</option>
                    <option v-for="condition in conditions" :key="condition.ID" :value="condition">{{ condition.Description }}</option>
                </select>
                <br>
                <button class="btn" @click="goToForm">Continue >></button>
            </div>
            <button class="info" v-on:click="showInfo = !showInfo">?</button>
            <div class="infoShow" v-if="showInfo">
                <h1>How To Rate Your Vehicle Condition</h1>
                <h3>Excellent</h3>
                <p>
                    “Excellent” condition means that the vehicle looks new and is in excellent mechanical condition. This
                    vehicle has never had any paint touch-ups and/or bodywork and does not need reconditioning. The
                    engine compartment is clean and free of leaks. This vehicle is free of rust. The body and interior are
                    free of wear or visible defects. The wheels are flawless and the tires all match and are like new. This
                    vehicle has a clean vehicle history report and will pass a safety inspection. This vehicle has complete
                    and verifiable service records.
                    Kelley Blue Book does not rate the condition of the vehicle. Rating the vehicle is the exclusive
                    responsibility of the user. The condition descriptions are provided to guide in the assessment of
                    vehicles.
                </p>
                <h3>Very Good</h3>
                <p>
                    “Very Good” condition means that the vehicle has minor cosmetic defects and is in excellent mechanical
                    condition. This vehicle has had minor paint touch-ups and/or bodywork, and requires minimal
                    reconditioning. The engine compartment is clean and free of leaks. This vehicle is free of rust. The 
                    4
                    body and interior have minimal signs of wear or visible defects. The tires all match and have 75% or
                    more of tread remaining. This vehicle has a clean vehicle history report and will pass a safety
                    inspection. Most service records are available.
                    Kelley Blue Book does not rate the condition of the vehicle. Rating the vehicle is the exclusive
                    responsibility of the user. The condition descriptions are provided to guide in the assessment of
                    vehicles.
                </p>
                <h3>Good</h3>
                <p>
                    “Good” condition means that the vehicle has some minor cosmetic repairable defects and is free of
                    mechanical problems. This vehicle has had minor paint touch-ups and/or bodywork. The engine
                    compartment may have minor leaks. This vehicle has only minor rust, if any. The body and interior have
                    normal signs of wear or visible defects. The wheels may have minor repairable scratches or scrapes.
                    All tires match and have at least 50% or more tread remaining. This vehicle has a clean vehicle history
                    report and will pass a safety inspection. Most service records are available.
                    Kelley Blue Book does not rate the condition of the vehicle. Rating the vehicle is the exclusive
                    responsibility of the user. The condition descriptions are provided to guide in the assessment of
                    vehicles.
                </p>
                <h3>Fair</h3>
                <p>
                    “Fair” condition means that the vehicle has cosmetic defects that require repairing and/or replacing and
                    may require minimal mechanical maintenance. This vehicle may need some mechanical repairs. The
                    paint and bodywork may require refinishing and body repair. The engine compartment has leaks and
                    may require minimal repairs. This vehicle may have some repairable rust damage. The body has dings,
                    chips, and/ or scratches and the interior has substantial wear, and may have small tears. The wheels
                    may be warped or bent, have scratches and might require replacement and tires may need replacing.
                    This vehicle needs servicing, but is still in reasonable running condition. Few service records available.
                    Kelley Blue Book does not rate the condition of the vehicle. Rating the vehicle is the exclusive
                    responsibility of the user. The condition descriptions are provided to guide in the assessment of
                    vehicles.
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'condition',
    metaInfo: {
        title: 'Condition of the vehicle you want to sell',
        titleTemplate: '%s | YourCarSold.com.au'
    },
    props: ['car', 'numPlates', 'state'],
    data: function() {
        return {
            kilometers: '',
            condition: '',
            conditions: [],
            showInfo: false
        }
    },
    mounted: function() {
        this.getGrades()
    },
    methods: {
        goToForm() {
            if( this.$data.kilometers == 0 || this.$data.condition == ''){
                this.$alert('Please enter both mileage and condition', 'Error','error')
                return } 
            else {
                fetch('https://api.kbb.com.au/Gps/GetPriceAdvisor?' + new URLSearchParams({
                    SecurityToken: process.env.VUE_APP_KBB_KEY,
                    Language: 'en-AU',
                    VehicleID: this.$props.car.ID,
                    VehiclePriceTypeID: 3,
                    VehicleGradeID: this.$data.condition.ID,
                    PriceDefinition: true,
                    Mileage: this.$data.kilometers
                }) , {
                    method: 'get',
                })
                .then((response) => {
                    return response.json()
                })
                .then((jsonData) => {
                    var carData = {
                        kilometers: this.$data.kilometers,
                        condition: this.$data.condition.Description,
                        numPlates: this.$props.numPlates,
                        state: this.$props.state,
                        ...this.car
                    }
                    this.$router.push({name: 'final-form', params: {output: jsonData.Response, carData}})
                })
            }
        },

        getGrades() {
            fetch('https://api.kbb.com.au/gps/GetAllVehicleGrade?' + new URLSearchParams({
                securityToken: process.env.VUE_APP_KBB_KEY
            }) , {
                method: 'get',
            })
            .then((response) => {
                return response.json()
            })
            .then((jsonData) => {
                this.conditions = jsonData.Response
            })
        },
    }
}
</script>

<style scoped>
    .wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px 20px 80px 20px;
        flex-direction: column;
    }

    .condition-wrapper {
        background-color: rgb(0, 0, 0, 0.8);
        max-width: 960px;
        height: auto;
        width: 100%;
        margin: 0 auto;
        border: 1px solid #00B5E2;
        border-radius: 4px;
        margin-bottom: 10px;
        padding: 20px 20px 20px 20px;
    }

    .cond-inner {
        margin: 0 auto;
        max-width: 500px;
    }

    .input-text {
        background-image: none;
        padding-left: 10px;
    }

    .info {
        border-radius: 50%;
        padding: 4px 7px;
        background-color: #000;
        color: #00B5E2;
        border: 1px solid #00B5E2;
        cursor: pointer;
        margin: 6px 6px;
    }

    .info:focus {
        outline-style: none;
    }

    .info:hover {
        color: #fff;
        background-color: #00B5E2;
    }

    .infoShow {
        font-size: small;
    }

    p {
        padding: 6px 0px;
    }
</style>