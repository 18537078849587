<template>
    <div class="displayWrapper">
        <h1>Your Kelley Blue Book&reg; Trade-In Valuation:</h1>
        <div class="valuationDisplay">
            <p>
                Thank you for choosing <a href="/">Your<span class="whiteText">Car</span>Sold</a> to value your vehicle. Please see your <a href="https://www.kbb.com.au">Kelley Blue Book&reg;</a> Trade-In Range valuation below:
            </p>
            <p>    
                For a firm price on this vehicle a physical inspection must be performed at one of <router-link to="/">YourCarSold</router-link>'s buying centres, 
                one of our team members will contact you soon to arrange an appointment for your inspection.
            </p>
            <div class="output-container">
                <div v-html="this.$props.output" class="fanConstraint" />
                <ul id="valueDetails">
                    <li>
                        <button class="btn" @click="callUs">
                            Not happy with your KBB valuation?<br />
                            <span id="direct"><i class="fa fa-phone"></i> Call Us Today</span><br />
                            Let us help shop around our dealer network to you get the best price.
                        </button>
                    </li>
                    <li>Value provided: {{ todaysDate.toDateString() }} for {{ state }}</li>
                    <li>{{ this.$props.carData.BrandName + ' ' + this.$props.carData.ModelName + ' ' + this.$props.carData.VersionName + ' ' + this.$props.carData.Year + ' ' + this.$props.carData.VehicleSeriesName }}</li>
                    <li>Condition: {{ this.$props.carData.condition }}</li>
                    <li>Kilometers: {{ this.$props.carData.kilometers }}</li>
                </ul>
            </div>
            <p>
                <strong>Kelley Blue Book&reg; Trade-In Range:</strong>
                Updated monthly, the Kelley Blue Book&reg; Trade-In Range is Kelley Blue Book's estimate of what a
                consumer can expect to receive this month based on the style, condition, kilometers and options of the
                vehicle when they trade it in to a dealer. However, every dealer is different and values are not
                guaranteed.
            </p>    
            <p>    
                Values and pricing are the opinions of Kelley Blue Book, and the actual price of a vehicle may vary.
                The value and pricing information displayed for a particular vehicle is based upon the specification,
                kilometers and/or condition information provided by the person generating this report. Kelley Blue
                Book assumes no responsibility for errors or omissions.
            </p>
            <p>
                ©2020 Kelley Blue Book Co., Inc. All rights reserved. As of {{ todaysDate.toDateString() }} Edition for {{ state }}.
            </p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'valuation',
    metaInfo: {
        title: 'Your FREE Valuation',
        titleTemplate: '%s | YourCarSold.com.au'
    },
    props: ['carData', 'output', 'state', 'enhanced_conversion_data'],
    data() {
        return {
            todaysDate: new Date()
        }
    },
    methods: {
        callUs() {
            window.open("tel:+61861866777")
        },
        pushEmail() {
            this.$gtag.query('event', 'form_submit', {
                enhanced_conversion_data: this.enhanced_conversion_data
            })
        }
    },
    mounted: function() {
        this.pushEmail()
    }
}
</script>

<style scoped>
h1 {
    margin-bottom: 20px;
}
    .displayWrapper {
        padding: 20px;
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .valuationDisplay {
        background-color:rgb(0, 0, 0, 0.9);
        border: 1px solid #00b5e2;
        border-radius: 4px;
        padding: 20px 0px;
        width: 100%;
        max-width: 960px;
        margin: 0 auto;
        margin-bottom: 80px;
        box-shadow: 4px 4px 6px rgb(0, 0, 0, 0.8);
    }

    .output-container {
        padding: 20px 20px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        margin: 0 auto;
    }

    p {
        font-size: x-small;
        margin: 0px 20px;
        padding-bottom: 10px;
    }

    li {
        list-style-type: none;
        padding: 6px 0px;
    }

    .btn {
        max-width: 300px;
        line-height: 30px;
        margin-top: 0;
        margin-bottom: 20px;
    }

    #direct {
        color: #fff;
        font-weight: 600;
        font-size: x-large;
    }

    @media screen and (min-width: 800px) {

        .valuationDisplay {
            margin-bottom: 0;
        }

        .output-container {
            flex-direction: row;
        }

        .fanConstraint {
            width: 50%;
        }

    }
</style>