<template>
  <div class="booking-form-wrapper" v-if="showForm">
    <form class="form-box" id="formData" @submit.prevent="sendBooking">
      <h1>Book In Today!</h1>
      <h2>Choose a time that suits you...</h2>
      <label for="name">Name</label>
      <input
      type="text"
      class="input-text"
      v-model="name"
      placeholder="John Smith"
      name="user_name"
      />
      <label for="email">Email</label>
      <input
        type="email"
        class="input-text"
        v-model="email"
        placeholder="john@mail.com"
        name="user_email"
      />
      <label for="number">Phone Number</label>
      <input
        type="number"
        class="input-text"
        v-model="number"
        placeholder="04XX XXX XXX"
        name="user_number"
      />
      <label for="Rego">Rego</label>
      <input
        type="text"
        class="input-text"
        v-model="rego"
        placeholder="XXXX XXX"
        name="user_rego"
      />
      <label for="car">Type of Car</label>
      <input
        type="text"
        class="input-text"
        v-model="car"
        placeholder="eg. Holden Commodore"
        name="user_car"
      />
      <label for="Preferred Date">Preferred Date</label>
      <input type="date" class="input-text" v-model="date" placeholder="" name="user_date" />
      <label for="Preferred Time">Preferred Time</label>
      <input type="time" class="input-text" v-model="time" placeholder="" name="user_time" />
      <button type="submit" class="btn">
        <span id="btnText">Submit</span>
        <div class="spin">
          <Spinner />
        </div>
        <span id="thank-you">Booking Submitted - Thank You!</span>
      </button>
      <div class="exit" @click="showForm = !showForm"></div>
    </form>
  </div>
</template>

<script>
import emailjs from '@emailjs/browser';
import Spinner from "vue-simple-spinner";

export default {
  name: "booking",
  data: () => {
    return {
      name: "",
      number: "",
      email: "",
      rego: "",
      car: "",
      date: "",
      time: '',
      showForm: true
    };
  },
  components: {
    Spinner,
  },
  methods: {
    thankYouDelay() {
        this.showForm = !this.showForm
    },
    sendBooking(e) {
      if (!this.name || !this.number || !this.rego || !this.date || !this.time || !this.email || !this.car) {
        this.$alert("Please Fill In All Required Fields");
      } else {
        document.querySelector("#btnText").style.display = "none";
        document.querySelector(".spin").style.display = "flex";
        emailjs.sendForm(
            "ycs-booking-server",
            "ycs_booking",
            e.target,
            "user_FspPAaPOMGXLWrCTunqwL"
          )
          .then(
            (result) => {
              console.log("SUCCESS!", result.status, result.text);
              document.querySelector(".spin").style.display = "none";
              document.querySelector('#thank-you').style.display = "flex";
              setTimeout(this.thankYouDelay, 3000);
            },
            (error) => {
              console.log("FAILED...", error);
            }
          );
      }
    },
  },
};
</script>

<style>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.booking-form-wrapper {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  padding: 20px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 100;
}

.form-box {
  max-width: 420px;
  padding: 10px 20px 20px 20px;
  border-radius: 6px;
  border: 1px solid #00b5e2;
  background-color: rgba(0, 0, 0, 0.9);
  text-align: left;
  position: relative;
}

.input-text {
  background-image: none;
  padding: 10px 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.exit::after {
  content: "X";
  position: absolute;
  color: #fff;
  top: 5px;
  right: 10px;
  cursor: pointer;
}

.spin {
  display: none;
  align-items: center;
  justify-content: center;
}

#thank-you {
  display: none;
  color:#fff;
  font-weight: 600;
  text-align: center;
  align-items: center;
  justify-content: center;
}

#btnText {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>