<template>
    <section class="blog-container">
        <h1>LATEST BLOGS</h1>
        <div class="bottomspace">       
            <section class="blog-item" v-for="blog in blogs" :key="blog.sys.id">
                <div class="image-left">
                    <router-link :to="'/blog/' + blog.sys.id">
                        <img class="post-image" :src="blog.fields.heroImage.fields.file.url" />
                    </router-link>
                </div>
                <div class="first-para">
                    <h2>
                        <router-link :to="'/blog/' + blog.sys.id">
                            {{ blog.fields.title }}
                        </router-link>
                    </h2>
                    <p class="date">
                        {{ blog.sys.createdAt.toString().replace("T", " @ ").slice(0, -8) }}
                    </p>
                    <p>
                        {{ blog.fields.body.content[0].content[0].value }}...
                    </p>
                </div>
            </section>
        </div>
    </section>
</template>

<script>
const contentful = require('contentful')

const client = contentful.createClient({
  space: process.env.VUE_APP_CONTENTFUL_SPACE_ID,
  environment: 'master', 
  accessToken: process.env.VUE_APP_CONTENTFUL_ACCESS_TOKEN
})


export default {
    name: 'Blog',
    data() {
        return {
            blogs: []
        }
    },
    metaInfo: {
        title: 'Our Blog',
        titleTemplate: '%s | YourCarSold.com.au'
    },
    created() {
        client.getEntries({ content_type: "blog" })
        .then((entries) => { 
            this.blogs = entries.items
            console.log(this.blogs)
        })
        .catch(console.error)
    }
}
</script>

<style scoped>
    h1 {
        margin-bottom: 16px;
    }

    h2 {
        text-align: left;
        margin-top: 10px;
    }

    .date {
        margin-bottom: 6px;
    }

    p {
        text-align: justify;
    }

    .blog-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 20px 20px 80px 20px;
    }

    .blog-item {
        color:#fff;
        gap: 10px;
        width: 100%;
        max-width: 400px;
        margin: 0 auto;
        background-color: rgb(21.2, 21.2, 21.2, 0.8);
        border-radius: 4px;
        border: 1px solid #00B5E2;
        padding: 10px;
        box-shadow: 4px 4px 6px rgb(0, 0, 0, 0.8);
    }

    .post-image {
        width: 100%;
        border-radius: 4px;
        min-height: 100px;
    }

    .image-left {
        border-radius: 4px;
    }

    .first-para {
        font-size: small;
    }

    .bottomspace {
        display: flex;
        flex-direction: column;
        max-width: 1300px;
        gap: 10px;
        margin: 0 auto;
    }

    @media screen and (min-width: 960px) {


        .blog-item {
            flex-direction: row;
            max-width: 600px;
        }

        .bottomspace {
            flex-direction: row;
        }


    }

</style>