<template>
<div class="contact-wrapper">
    <div class="contact"> <!-- CONTACT START -->
      <div class="contact-left">
          <h3>ADDRESS:</h3>
          <div class="add-icon-wrap">
            <div>
              <i class="fab fa fa-map-pin white"></i>
            </div>
            <div>
              <p>
                <a href="https://maps.google.com/maps?cid=14436392137456650368&authuser=1&_ga=2.37561764.584584341.1628818239-1151572983.1607501795" target="_blank">
                  2/26 Lancaster Road
                  <br>
                  <strong class="blueFont">Wangara</strong> WA 6065
                </a>
              </p>
            </div>
            <div class="closest-station">
            <div>
              <i class="fab fa fa-train white"></i>
            </div>
            <div>
              <p>
                <a href="https://www.transperth.wa.gov.au/Using-Transperth/Station-Facilities/Stations-Maps?sid=49" target="_blank">
                  Edgewater Station (4.7km)
                </a>
              </p>
            </div>
          </div>
          </div>
          
          <div class="add-icon-wrap">
            <div>
              <i class="fab fa fa-map-pin white"></i>
            </div>
            <div>
              <p>
                <a href="https://www.google.com/maps/place/Your+Car+Sold/@-32.0359893,115.5056402,10z/data=!4m9!1m2!2m1!1syourcarsold!3m5!1s0x2a32bb9d3ce0f4d1:0x9ae68a5e2a7dbcc5!8m2!3d-31.9565283!4d115.9065612!15sCgt5b3VyY2Fyc29sZJIBFG1vdG9yX3ZlaGljbGVfZGVhbGVy" target="_blank">
                  37 Gt. Eastern Hwy
                  <br>
                  <strong class="blueFont">Rivervale</strong> WA 6103
                </a>
              </p>
            </div>
            <div class="closest-station">
            <div>
              <i class="fab fa fa-train white"></i>
            </div>
            <div>
              <p>
                <a href="https://www.transperth.wa.gov.au/Using-Transperth/Station-Facilities/Stations-Maps?stid=2&sid=53" target="_blank">
                  Burswood Station (750m)
                </a>
              </p>
            </div>
          </div>
          </div>

          <div class="add-icon-wrap">
            <div>
              <i class="fab fa fa-map-pin white"></i>
            </div>
            <div>
              <p>
                <a href="https://www.google.com/maps/place/Your+Car+Sold/@-32.2794871,115.7444838,15.75z/data=!4m9!1m2!2m1!1syour+car+sold!3m5!1s0x2a3283a0d55ec40b:0xd7ab4680b3116912!8m2!3d-32.2761785!4d115.7534285!15sCg15b3VyIGNhciBzb2xkkgEKY2FyX2RlYWxlcg" target="_blank">
                  45B Dixon Road
                  <br>
                  <strong class="blueFont">Rockingham</strong> WA 6168
                </a>
              </p>
            </div>
            <div class="closest-station">
            <div>
              <i class="fab fa fa-train white"></i>
            </div>
            <div>
              <p>
                <a href="https://www.transperth.wa.gov.au/Using-Transperth/Station-Facilities/Stations-Maps?sid=40" target="_blank">
                  Rockingham Station (1.7km)
                </a>
              </p>
            </div>
          </div>
          </div>
          <hr />
          <h3>PHONE:</h3>
          <p>
            Head Office:
            <a href="tel:+618618667777" class="about-contact-links">
                (08) 618 66 777
            </a>
          </p>
          <h3>EMAIL:</h3>
          <p>
            <a href="mailto:info@yourcarsold.com.au" class="about-contact-links">
                info@yourcarsold.com.au
            </a>
          </p>
          <h3>SOCIAL:</h3>
          <p>
            <ul id="soc-list">
              <li><a href="https://www.facebook.com/yourcarsold/" target="_blank"><i class="fab fa-facebook-f"></i> @yourcarsold</a></li>
              <li><a href="https://www.twitter.com/yourcarsold/" target="_blank"><i class="fab fa-twitter"></i> @yourcarsold</a></li>
              <li><a href="https://www.instagram.com/yourcarsold.com.au/" target="_blank"><i class="fab fa-instagram"></i> @yourcarsold</a></li>
            </ul>
          </p>
          <h3>USEFUL LINKS:</h3>
          <p>
            <ul id="soc-list">
              <li><a href="https://www.mtawa.com.au/" target="_blank">MTA | WA</a></li>
              <li><a href="https://www.transport.wa.gov.au/licensing/my-vehicle.asp/" target="_blank">DOT / Licensing</a></li>
              <li><a href="https://perth.wa.gov.au/" target="_blank">Perth City Council</a></li>
            </ul>
          </p>
      </div>
              
      <div class="contact-right">
          <iframe src="https://www.google.com/maps/d/embed?mid=1Ox5vhR1l2j2kmqi0DqnmzKGbWG42uXw&ehbc=2E312F" width="100%" height="480px"></iframe>
      </div>
    </div>
  </div> <!-- CONTACT END -->
</template>

<script>
export default {
  name: "contact",
  data: () => ({
    ycsTheme: {
        nav: "#00b5e2",
        navItem: "#fff",
        navActiveItem: "#131313",
        slider: "#131313",
        arrow: "#fff",
      },
  }),
};
</script>

<style>

.contact-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.contact {
  display: flex;
  justify-content: space-between;
  padding: 20px 20px;
  border-radius: 6px;
  border: 1px solid #00b5e2;
  width: 100%;
  max-width: 1300px;
}

.contact-right {
  max-width: 600px;
  width: 100%;
}

h3 {
  text-align: left;
  margin-top: 20px;
}

.contact-left p,
.contact-right p {
  font-size: small;
  padding: 10px;
  text-align: left;
  margin-right: 20px;
}

.contact-left a {
  color: #fff;
}

.contact-left a:hover {
  color: #00b5e2;
}

.contact-left li {
  list-style-type: none;
  padding: 6px 0px;
}

hr {
  margin: 10px 0px 10px 0px;
}

.add-icon-wrap, .closest-station {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 820px) {
  .contact {
    flex-direction: column;
  }

  .contact-wrapper {
    padding: 0px 0px 80px 0px;
  }

  .contact-right {
    margin-top: 10px;
  }
}


</style>