<template>
    <div class="search">
        <div class="searchWrapper">
            <h1>{{ heading }}</h1>
            <h2>{{ subTitle }}</h2>
            <!-- <div class="notification" @click="callUs()">
                <h4 class="down">Apologies, our KBB valuation system is currently down. Please book straight in using the below button or click here to call directly and speak to an agent.</h4>
            </div> -->
            <input type="text" @keyup.enter="getCarData" aria-label="RegoInput" class="input-text" v-model="plates" placeholder="Enter registration..">
            <br>
            <button class="btn" @click="getCarData">Get Valuation >></button>
            <div class="spin">
                <Spinner />
            </div>
        </div>
    </div>
        
</template>

<script>
import Spinner from 'vue-simple-spinner'
export default {
    name: 'search',
    props: ['heading', 'subTitle'],
    components: {
        Spinner
    },
    data() {
        return {
            states: ['WA','NSW','QLD','NT','ACT','TAZ','SA','VIC'],
            cars: '',
            plates: '',
            state: 'WA',
        }
    },
    methods: {
        getCarData() {
            if( this.plates == '' || this.state == ''){
                this.$alert('Please enter valid values', 'Error','error')
                return
            }
            document.querySelector('.spin').style.display="flex";
            fetch('https://api.kbb.com.au/Gps/GetVehiclesbyLicenceplate?' + new URLSearchParams({
                securityToken: process.env.VUE_APP_KBB_KEY,
                numberPlate: this.plates,
                state: this.state
            }) , {
                method: 'get',
            })
            .then((response) => {
                return response.json()
            })
            .then((jsonData) => {
                if (jsonData.ResponseStatus == 0) {
                    this.$router.push({name: 'full-search', params: {state: this.$data.state}})
                    return
                }
                this.cars = jsonData.Response
                this.$router.push({name: 'confirmation', params: {cars: jsonData.Response, numPlates: this.$data.plates, state: this.$data.state}})   
            })         
        },
        logKeyup(e) {
            console.log(e)
        },
        callUs() {
            window.open("tel:+61861866777")
        }

    }
}
</script>

<style scoped>
    .search {
        width: 100%;
    }

    .searchWrapper {
        margin: 0 auto;
        width: 100%;
        max-width: 500px;
    }

    .spin {
        position: fixed;
        height: 100vh;
        width: 100vw;
        background-color: rgb(0, 0, 0, 0.6);
        z-index: 1;
        top: 0;
        left: 0;
        display: none;
        justify-content: center;
        align-items: center;
    }

    /* .disTip:hover {
        cursor: not-allowed;
    }

    .btn:hover {
        cursor: not-allowed;
    } */

    /* .btn {
        background-color: rgba(255,255,255,0.2);
    } */

    .down {
        color: white;
        font-weight: 300;
        line-height: 24px;
    }

    .notification {
        border: 1px solid red;
        border-radius: 6px;
        background-color: rgba(255,255,255,0.1);
        padding: 10px 20px;
        margin-bottom: 10px;
        cursor: pointer;
    }
</style>