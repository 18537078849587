<template>
  <div class="where-cont">
    <Contact />
  </div>
</template>

<script>
import Contact from "../components/Contact.vue"
export default {
    name: 'where',
    metaInfo: {
        title: 'Where are we?',
        titleTemplate: '%s | YourCarSold.com.au'
    },
    components: {
        Contact
    }
};
</script>

<style>
.where-cont {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 20px;
}
</style>