<template>
    <div class="wrapper">
        <div class="main">
            <div class="sm-l">
                <img :src="require('../assets/' + member.fields.img)" height="300px" />
            </div>
            <div class="sm-r">
                <h2>{{ member.fields.name }}</h2>
                <h3>{{ member.fields.position }} | {{ member.fields.branch }}</h3>
                <p>
                    {{ member.fields.desc.content[0].content[0].value }}
                </p>
                <div class="buttons">
                    <button class="btn" type="button" :value="'Call' + member.fields.name.split(' ', 1)[0]" @click="callMember">
                        Call {{ member.fields.name.split(' ', 1)[0] }}
                    </button>
                    <button class="btn" type="button" :value="'Email' + member.fields.name.split(' ', 1)[0]" @click="emailMember">
                        Email {{ member.fields.name.split(' ', 1)[0] }}
                    </button>
                </div>
            </div>
        </div>
        <Search heading="Search Rego Now" subTitle="Get Your Free Kelley Blue Book Valuation" style="margin: 80px 0px 80px 0px;" />
    </div>
</template>

<script>
import Search from './Search.vue';
export default {
    name: 'staff-profile',
    props: ['member'],
    components: {
        Search
    },
    methods: {
        callMember() {
            window.open("tel:+" + this.member.fields.no)
        },
        emailMember() {
            window.open("mailto:" + this.member.fields.email)
        }
    }
}

</script>

<style scoped>
.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
    margin: 10px;
}
.main {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    background-color: rgba(0,0,0,0.8);
    border: 1px solid #00b5e2;
    border-radius: 10px;
    padding: 20px;
    width: 100%;
    max-width: 1300px;  
}

h2 {
    color: #fff;
    text-align: left;
    margin: 0;
}

h3 {
    margin: 0;
    margin-bottom: 6px;
}

.sm-r {
    text-align: left;
    padding: 10px;
    width: 100%;
    max-width: 800px;
}

p {
    line-height: 26px;
}
.buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding-top: 20px;
}

.btn {
    max-width: 400px;
    margin: 0;
}

img {
    border-radius: 6px;
    margin: 10px;
}
</style>