<template>
  <!-- TESTIMONIALS START -->
  <div class="testimonials">
    <h1>TESTIMONIALS</h1>
    <h2>A quick word from our happy customers:</h2>

    <!-- REVIEW CONTAINER START -->
    <!-- <div class="carousel-container"> -->
    <div class="slider">
      <div class="slides">
        <div class="review-card" v-for="review in reviews" :key="review.index">
          <a
            href="https://www.google.com/maps/place/Your+Car+Sold/@-31.7964193,115.67952,11z/data=!4m11!1m2!2m1!1syourcarsold!3m7!1s0x2a32ad87bf572c63:0xc8585e2967588880!8m2!3d-31.7963464!4d115.8195894!9m1!1b1!15sCgt5b3VyY2Fyc29sZFoNIgt5b3VyY2Fyc29sZJIBD3VzZWRfY2FyX2RlYWxlcg"
            target="_blank"
          >
            <div class="review-header">
              <img :src="review.profile_photo_url" alt="Author Image" />
              <h2>{{ review.author_name }}</h2>
            </div>

            <div class="review-rating">
              <div class="star-cont">
                <div>
                  <img src="../assets/stars_blank.png" alt="img" />
                </div>
                <div
                  class="cornerimage"
                  :style="{ width: (review.rating / 5) * 100 + '%' }"
                >
                  <img src="../assets/stars_full.png" alt="" />
                </div>
              </div>
              <span id="numerical-rating">{{ review.rating }} Stars</span>
            </div>

            <p class="review-content" v-shave="{ height: 70 }">
              {{ review.text }}
            </p>
          </a>
        </div>
      </div>
      <p class="totals">Showing <span class="blue">{{ reviews.length }}</span> of <span class="blue">{{ mapInfo.user_ratings_total }}</span> | Avg. Rating: <span class="blue">{{ mapInfo.rating }}</span></p>
    </div>
    <!-- REVIEW CONTAINER END -->
  </div>
  <!-- TESTIMONIALS ENDS -->
</template>

<script>

export default {
  name: "testimonials",
  data: () => {
    return {
      reviews: [],
      mapInfo: ''
    };
  },
  computed: {
    filteredReviews() {
      return this.reviews.slice(0, 3);
    },
  },
  methods: {
    fetchReviews() {
      fetch(
        "https://ycs-cw.onrender.com/https://maps.googleapis.com/maps/api/place/details/json?" + new URLSearchParams({
          placeid: 'ChIJ0fTgPJ27MioRxbx9Kl6K5po',
          key: process.env.VUE_APP_G
        }),
        {
          method: "get",
          credentials: "same-origin",
          headers: {
            Origin: "https://maps.googleapis.com/",
          },
        }
      )
        .then((response) => {
          return response.json();
        })
        .then((reviewObject) => {
          this.reviews = reviewObject.result.reviews;
          this.mapInfo = reviewObject.result;
        });
    },
  },
  mounted: function() {
    this.fetchReviews();
  },
};
</script>

<style>
h1,
h2 {
  text-align: center;
}

h2 {
  margin-bottom: 20px;
}

.testimonials {
  width: 100%;
  margin: 10px 0px 20px 0px;
}

.carousel-container {
  width: 100%;
  display: flex;
  /* flex-wrap: wrap; */
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.totals {
  margin: 6px 0px 6px 0px;
}

.blue {
  color:#00b5e2;
}

.review-card {
  text-align: justify;
  max-width: 400px;
  height: 200px;
  width: 100%;
  padding: 20px 20px;
  border-radius: 6px;
  border: 1px solid #00b5e2;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.8);
  background-color: rgba(0, 0, 0, 0.15);
}

.review-card:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.review-card h3 {
  margin: 10px 0px 10px 0px;
}

.review-header {
  display: flex;
}

.review-header img {
  max-width: 40px;
  max-height: 40px;
  margin: 0px 10px 10px 0px;
}

.review-content {
  font-size: 12px;
  color: #fff;
  line-height: 16px;
  margin-top: 10px;
}

.review-rating {
  display: flex;
}

#numerical-rating {
  margin-left: 10px;
  font-size: 16px;
  font-weight: 600;
}

.star-cont {
  border: 0;
  position: relative;
  width: 100px;
}

.cornerimage {
  border: 0;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.star-cont img {
  max-width: 100px;
}

/* START OF SLIDER CSS */

.slider {
  width: 100%;
  text-align: center;
  overflow: hidden;
}

.slides {
  display: flex;
  align-items: center;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  
  /*
  scroll-snap-points-x: repeat(300px);
  scroll-snap-type: mandatory;
  */
}
.slides::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
.slides::-webkit-scrollbar-thumb {
  background: black;
  border-radius: 10px;
}
.slides::-webkit-scrollbar-track {
  background: transparent;
}
.slides > div {
  scroll-snap-align: start;
  flex-shrink: 0;
  width: 300px;
  height: 200px;
  margin-right: 20px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.1);
  transform-origin: center center;
  transform: scale(1);
  transition: transform 0.5s;
  position: relative;
  
  display: flex;
  justify-content: center;
  align-items: center;
}
.slides :last-child {
  margin-right: 0;
}




/* END OF SLIDER CSS */


@media screen and (max-width: 780px) {
  .carousel-container {
    flex-direction: column;
  }
}

@media screen and (max-width: 540px) {
  .slides > div {
    min-width: 100%;
    max-width: 100%;
  }
}


</style>
