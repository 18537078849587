<template>
  <div class="location-wrapper">
    <h2>{{ title }}</h2>
    <div class="main">
      <div class="suburb-list">
        <div class="loc" v-for="location in northernSuburbs" :key="location.ID">
          <router-link :to="{name: 'location-lander', params: { suburb: location.suburb, region: location.region } }">
              {{ location.suburb }}
          </router-link>
        </div>
      </div>
      <div class="suburb-list">
        <div class="loc" v-for="location in centralSuburbs" :key="location.ID">
          <router-link :to="{name: 'location-lander', params: { suburb: location.suburb, region: location.region } }">
              {{ location.suburb }}
          </router-link>
        </div>
      </div>
      <div class="suburb-list">
        <div class="loc" v-for="location in southernSuburbs" :key="location.ID">
          <router-link :to="{name: 'location-lander', params: { suburb: location.suburb, region: location.region } }">
              {{ location.suburb }}
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['locations'],
  name: 'location-list',
  data: () => {
    return {
      title: 'Serving the following locations...',
    };
  },
  computed: {
    northernSuburbs: function() {
      return this.locations.filter((location) => location.region == 'North');
    },
    centralSuburbs: function() {
      return this.locations.filter((location) => location.region == 'Central');
    },
    southernSuburbs: function() {
      return this.locations.filter((location) => location.region == 'South');
    },
  },
};
</script>

<style scoped>
h2 {
  text-align: center;
  margin-top: 10px;
}
.location-wrapper {
  width: 100%;
  max-width: 1300px;
  height: auto;
  background: rgba(0, 0, 0, 0.8);
  border: 1px solid #00b5e2;
  border-radius: 6px;
  margin-bottom: 10px;
}

a {
    color: #fff;
}

a:hover {
    color:#00b5e2;
}

ul {
  text-decoration: none;
  list-style-type: none;
}

.main {
  display: flex;
  flex-direction: column;
  font-size: xx-small;
}

.suburb-list {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  padding: 10px;
  justify-content: center;
}

.loc {
  padding: 4px;
}
</style>
