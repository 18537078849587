<template>
  <div class="wrapper">
    <h1>
      {{ heading }}
    </h1>
    <h2>
      {{ subHeading }}
    </h2>
    <div class="staff-cards">
      <StaffCard v-for="member in staff" :key="member.ID" :member="member" />
    </div>
  </div>
</template>

<script>
import StaffCard from './StaffCard.vue';

export default {
  name: 'the-team',
  props: ['staff'],
  components: {
    StaffCard,
  },
  data: () => {
    return {
      heading: 'MEET THE TEAM',
      subHeading: 'Always Here To Help',
    };
  },
};
</script>

<style scoped>

.staff-cards {
  width: 100%;
  max-width: 1300px;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  justify-content: center;
}
</style>
