<template>
    <footer>
        <div class="foot-inner">
            <div class="copy">
                &copy; 2020 - <a href="/">YourCarSold.com.au</a> - MD28541
            </div>
            <div class="socials">
                <ul>
                    <li><a href="https://www.facebook.com/yourcarsold/"><i class="fab fa-facebook-f"></i></a></li>
                    <li><a href="https://www.twitter.com/yourcarsold/"><i class="fab fa-twitter"></i></a></li>
                    <li><a href="https://www.instagram.com/yourcarsold.com.au/"><i class="fab fa-instagram"></i></a></li>
                    <li><router-link to="/where"><i class="fab fa fa-map-pin white"></i></router-link></li>
                </ul>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'site-footer'
}
</script>

<style scoped>
    a {
        color: #00b5e2;
        text-decoration: none;
    }

    a :hover {
        color: #fff;
        transition: 0.8s;
    }

    footer {
        display: grid;
        place-items: center;
        background-color: rgba(0, 0, 0, 0.9);
        bottom: 0;
        height: auto;
        width: 100%;
        border-top: 1px solid #00b5e2;
        font-size: smaller;
        padding: 10px 0px;
        margin-top: 10px;
        position: fixed;
        z-index: 99;
    }

    .copy .address .telBottom {
        padding: 0;
    }

    .socials li {
        display: inline-block;
        margin: 6px 10px;
    }
    
    @media screen and (min-width: 1300px) {
        
        footer {
            display: flex;
            justify-content: center;
            width: 100%;
            margin: 0 auto;
            margin-top: 10px;
        }

        .foot-inner {
            display: flex;
            width: 100%;
            max-width: 1300px;
            margin: 0 auto;
            place-items: center;
            justify-content: space-between;
        }

        .socials {
            text-align: right;
        }
    }
</style>